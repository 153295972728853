/*
Эти стили подключаются последними,
фиксят некоторые фичи (из-за асинхронной подгрузки файла стилей)
например мелькание модального окна
*/
.secondary-screen {
  opacity: 1 !important;
}

.modal {
  display: block;
}
