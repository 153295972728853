.model {
  width: 100%;
  cursor: pointer;
}

.model__img-wrap {
  margin-bottom: .5rem;
  position: relative;
  padding-bottom: 42%;
}

.model__img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.2);
  transition: transform ease-in var(--duration-1);
}

.model:hover .model__img {
  transform: translate(-50%, -50%) scale(1.25);
}

.model__name-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5%;
}

.model__name-prefix,
.model__name-sufix {
  font-size: 13px;
  line-height: 13px;
  text-transform: uppercase;
}

.model__name-prefix {
  margin-right: 3px;
}

.model__name {
  margin-right: 3px;
  font-size: 36px;
  line-height: 30px;
}
