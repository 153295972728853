.photoline {
	display: flex;
	justify-content: center;
	align-items: stretch;
	flex-wrap: wrap;

	@media(--sm) {
		flex-wrap: nowrap;
	}
}

.photoline__img-wrap {
	width: 33.3333333%;
	overflow: hidden;
  font-size: 0;

	@media(--sm) {
		width: 16.6666666%;
	}
}

.photoline__img-inner {
  position: relative;
  width: 100%;
  padding-bottom: 66.666%;
}

.photoline__img {
	position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.03);
	width: 100%;
	transition: opacity ease-out .5s;
  opacity: 0;
}

.photoline__img.active {
	opacity: 1;
}
