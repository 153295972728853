.dealer {
  background-color: var(--color-1);
  padding: 1.5rem 1rem;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}

.dealer__top,
.dealer__bottom {
  width: 100%;
}

.dealer__bottom {
  align-self: flex-end;
}

.dealer__name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  margin-bottom: 1rem;

  @media (--sm) {
    font-size: 1em;
  }

  @media (--xl) {
    font-size: 1.4em;
  }

}

.dealer__icon {
  display: block;
  margin-right: 1rem;
  width: 25px;
}

.dealer__img-wrap {
  margin-bottom: 1rem;
}

.dealer__address,
.dealer__time {
  font-style: normal;
  margin-bottom: 1rem;
}

.dealer__phone {
  font-size: 2em;
  color: inherit;
  text-decoration: none;
  margin-bottom: .5rem;
  display: inline-block;
}

.dealer__button {
  margin-bottom: 1rem;
}

.dealer__link-app {
  margin-bottom: .3rem;
}

.dealer__link {
  font-size: .9em;
}

.js-dealer-trigger {
  cursor: pointer;
}
