@import "ion-rangeslider/css/ion.rangeSlider.css";

.filter {
  margin-bottom: 2rem;
}

.filter .row {
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.filter .col {
  width: 100%;
  margin-bottom: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;

  @media (--sm) {
    width: 33.333%;
  }
}

.filter .col_4 {
  width: calc(100% - 4rem);
  max-width: 500px;
}

.filter__range {
  width: 100%;
}

.irs-single {
  cursor: pointer;
}

.irs--flat .irs-handle {
  width: 20px;
  height: 20px;
  transform: translateX(-2px);
}

.irs--flat .irs-handle>i:first-child {
  background-color: var(--color-1);
  border: 2px solid var(--color-2);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
}

.irs--flat .irs-handle.from>i:first-child {
  left: 0;
}

.irs--flat .irs-bar,
.irs--flat .irs-from, .irs--flat .irs-single, .irs--flat .irs-to {
  background-color: var(--color-2);
}

.irs--flat .irs-handle:hover>i:first-child {
  background-color: var(--color-3);
}

.irs--flat .irs-from:before, .irs--flat .irs-single:before, .irs--flat .irs-to:before {
  border-top: var(--color-2);
}

.irs--flat .irs-max, .irs--flat .irs-min,
.irs--flat .irs-from, .irs--flat .irs-single, .irs--flat .irs-to {
  font-size: 14px;
}
