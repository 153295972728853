.advantages2 {
  padding-top: 3rem;
  padding-bottom: 2rem;
  box-shadow: var(--shadow-2);
  position: relative;

  @media (--sm) {
    padding-top: 5rem;
    padding-bottom: 3rem;
  }
}

.advantages2__title {
  position: relative;
  text-transform: uppercase;
}

.advantages2 .row {
  margin-bottom: -2em;
}

.advantages2 .col {
  width: 100%;
  margin-bottom: 2em;
  align-items: flex-start;

  @media (--sm) {
    width: 50%;
  }

  @media (--md) {
    align-items: flex-start;
    width: 25%;
  }

  @media (--lg) {
    width: 20%;
  }
}

