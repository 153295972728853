.icon-box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;

  @media (--sm) {
    justify-content: center;
    flex-wrap: wrap;
  }
}

.icon-box__img-wrap {
  display: inline-block;
}

.icon-box__img {
  width: 100px;
  display: inline-block;
  margin-right: 1rem;
  transition: .25s all ease-out;

  @media (--sm) {
    margin-right: 0;
    margin-bottom: .5rem;
    width: 140px;
  }
}

.icon-box__title {
  display: inline-block;
  font-size: 1.2em;
  line-height: 1.15em;
  width: calc(100% - 100px);
  text-align: left;

  @media (--sm) {
    width: 100%;
    font-size: 1em;
    text-align: center;
  }
}

.icon-box__note {
  font-size: 12px;
  text-align: left;
}
