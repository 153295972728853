.model2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: var(--color-1);
  border-bottom: 1px solid hsla(0, 0%, 85%, 1);
  position: relative;

  @media (--md) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.model2__anchor {

  @media (--sm) {
    position: absolute;
    top: -83px;
    left: 0;
    width: 100%;
    pointer-events: none;
  }
}


.model2:last-child {
  border-bottom: none;
}

.model2 .col {
  align-items: center;
  position: relative;

  @media (--sm) {
    width: 50%;
  }
}


.model2 .col_1 {
  margin-bottom: 1rem;
  max-width: 500px;
  width: 100%;

  @media (--sm) {
    margin-bottom: 0;
  }

  @media (--md) {
    width: 40%;
  }
}


.model2 .col_2 {
  max-width: 500px;

  @media (--xm) {
    width: 50%;
  }

  @media (--md) {
    width: 30%;
  }

  @media (--lg) {
    width: 35%;
  }
}


.model2 .col_3 {
  width: 100%;
  text-align: center;
  margin-top: 1rem;

  @media (--md) {
    margin-top: 0;
    width: 30%;
  }

  @media (--lg) {
    width: 25%;
  }
}

.model2 .col_4 {
  width: 100%;
  text-align: center;
  margin-top: 1rem;
}

.model2 .col > div {
  width: 100%;
}


.model2__name-wrap {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 5%;
}

.model2__name-prefix,
.model2__name-sufix {
  font-size: 21px;
  line-height: 22px;
  text-transform: uppercase;
}

.model2__name-prefix {
  margin-right: 5px;
}

.model2__name {
  margin-right: 5px;
  font-size: 56px;
  line-height: 48px;
}

.model2__img-wrap {
  position: relative;
}

.model2__button-stocks {
  margin-bottom: 1rem;
}

.model2__link {
  font-size: 16px;
}

.model2__offers {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  user-select: none;
  position: relative;
  z-index: 600;
}

.model2__offers-item {
  position: relative;
  padding-left: 1em;
  margin-bottom: .2em;
  font-size: .85em;
  text-transform: uppercase;
}

.model2__offers-item:last-child {
  margin-bottom: 0;
}

.model2__offers-item-dot {
  position: absolute;
  left: 0;
  top: 50%;
  width: 4px;
  height: 4px;
  transform: translateY(-50%);
  background-color: var(--color-3);

  @media (--md) {
    width: 8px;
    height: 8px;
  }
}

.model2__offers rub {
  font-size: .9em;
}

.model2__profits {
  display: none;

  @media (--md) {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }

  @media (--md) {
    position: absolute;
    left: 0;
    bottom: 1rem;
  }
}

.model2__profits__item {
  width: 50%;
  text-align: center;
  text-transform: uppercase;
  padding-right: .5rem;
  padding-left: .5rem;
  font-size: .9em;
  line-height: .9em;
  max-width: 170px;
}

.model2__profits__item__img-wrap {
  max-width: 100px;
  margin-bottom: .5rem;
  margin-right: auto;
  margin-left: auto;
}

.model2__button {
  margin-bottom: .2em;
  padding-left: 20px;
  padding-right: 25px;
  width: 300px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  @media (--md) {
    min-width: 100%;
  }
}

.model2__button i {
  top: 0;
}

.model2__button:last-child {
  margin-bottom: 0;
}

.model2__links {
  list-style: none;
  margin: 0 auto;
  display: inline-block;
  padding: 0;
  max-width: 300px;

  @media (--xm) {
    max-width: 100%;
  }
}

.model2__links_single {
  margin-bottom: 1rem;
}

.model2__links__item {
  text-align: left;
  display: inline-flex;
  padding: 1em .5em;
  font-size: 14px;
  line-height: 1.1em;
  margin-top: .5rem;
  margin-bottom: .5rem;
  transition: color ease-out var(--duration-1);
  flex-wrap: nowrap;
  position: relative;
  border-bottom: 1px solid var(--color-2);
  text-transform: uppercase;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;

  @media (--xm) {
    width: auto;
    font-size: 18px;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  @media (--md) {
    border-bottom: none;
    padding: 0 .2em;
  }
}

.model2__links_single .model2__links__item {
  margin: 0;
}

.model2__links__item::after {
  @media (--md) {
    content: "";
    position: absolute;
    left: 3px;
    bottom: 0;
    width: 2em;
    height: 1px;
    transition: all ease-in-out var(--duration-1);
    background: var(--color-2);
  }
}

.model2__links_single .model2__links__item::after {
  @media (--md) {
    width: 100%;
  }
}

.model2__links__item:hover:after {
  @media (--md) {
    width: 100%;
    background: var(--color-3);
  }
}

.model2__links__item i {
  display: inline-block;
  margin-left: 1em;
  transition: transform ease-in-out var(--duration-1);
}

.model2__links__item:hover i {
  @media (--md) {
    transform: translateX(10px);
  }
}

