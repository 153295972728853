.stock {
  display: none;
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: center;
  width: 100%;
  position: relative;
  border-bottom: 1px solid hsla(0, 0%, 80%, 1);
}

.stock .container {
  text-align: right;
  max-width: calc(1050px + 2rem);
}

.stock__inner {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.stock__item {
  width: 100%;
  justify-content: center;
  max-width: 1050px;
  margin: 0 auto 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: var(--color-1);
  position: relative;

  @media (--sm) {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

.stock__item:last-child {
  border-bottom: none;
}

.stock__item .col {
  align-items: center;
}

.stock__item .col_1 {
  width: 100%;
  margin-bottom: .5em;
  text-align: center;

  @media (--xm) {
    width: 80%;
  }

  @media (--sm) {
    width: 33.333%;
  }

  @media (--md) {
    margin-bottom: 0;
    width: 22%;
  }
}

.stock__item .col_2 {
  margin-bottom: .5em;
  text-align: center;

  @media (--xm) {
    margin-bottom: 0;
  }

  @media (--sm) {
    text-align: left;
  }
}

.stock__item .col_2,
.stock__item .col_3 {
  width: 100%;

  @media (--xm) {
    width: 50%;
  }

  @media (--sm) {
    width: 33.333%;
  }

  @media (--md) {
    width: 39%;
  }
}

.stock__item .col_1 > div {
  font-size: 0;
}

.stock__item .col > div {
  width: 100%;
}

.stock__item__img-wrap {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.stock__item__img {
  position: relative;
}

.stock__item__colors {
  text-align: center;
}

.stock__item__color {
  display: inline-block;
  margin: 3px;
  width: 25px;
  height: 25px;
  border: 1px solid hsla(0, 0%, 90%, 1);
}

.stock__item__complectation {
  font-size: 1.6em;
  color: var(--color-2);
}


.stock__item__char {
  font-size: 15px;
  padding: .2em;
  margin-bottom: .2em;
  text-align: left;
  color: #777;
}

.stock__item__char:last-child {
  margin-bottom: 0;
}

.stock__item__label {
  display: inline-block;
  margin-right: 1em;
  min-width: 6em;
  text-align: right;
}

.stock__item__val {
  color: var(--color-2);
}

.stock__item__color-name {
  text-align: center;
}

.stock__item__modification {
  margin-bottom: .5em;
}

.stock__item__show {
  color: #444;
  font-size: .8em;
}

.stock__item__price {
  color: var(--color-2);
  font-size: 1.4em;
  text-align: center;
}

.stock__item__price-note {
  margin-bottom: .2em;
}

.stock__item__button {
  width: 100%;

  @media (--sm) {
    width: auto;
  }
}

.stock__hide {
  min-width: 10em;
}

.stock__close {
  font-size: 25px;
  opacity: .8;
  transition: opacity ease-out var(--duration-1);
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;

  @media (--sm) {
    top: 10px;
    right: 15px;
    font-size: 35px;
  }
}

.stock__close:hover {
  opacity: 1;
}

.stock__empty {
  font-size: 2em;
  margin-bottom: 3rem;
}
