.map {
  position: relative;
  background: var(--color-4);
  z-index: 1;
  overflow: hidden;
  padding-top: 2rem;

  @media (--lg) {
    padding-top: 3rem;
  }
}

.map-content .container {
  padding-right: .5rem;
  padding-left: .5rem;
}

.map-content .row {
  margin-left: -.5rem;
  margin-right: -.5rem;
  margin-bottom: -1rem;
}

.map-content .col {
  padding-left: .5rem;
  padding-right: .5rem;
  margin-bottom: 1rem;
  max-width: 400px;


  @media (--sm) {
    width: 50%;
    max-width: 100%;
  }

  @media (--md) {
    width: 40%;
  }

  @media (--lg) {
    width: 25%;
  }
}


.map-container {
  height: 400px;
}

.map__balloon {
  background: var(--color-1);
  padding-top: .5rem;
  max-width: 320px;
  font-size: 16px;
}

.map__ballon-phone i {
  margin-right: .25em;
}

.map__balloon-dealer {
  font-size: 22px;
  line-height: 1em;
  color: var(--color-2);
  margin-bottom: .5rem;
}

.map__balloon-address {
  display: inline-block;
}

.map__balloon-phone {
  color: inherit;
  text-decoration: none;
  display: block;
}

.map__balloon-time {
  margin-bottom: .5rem;
}

.map__balloon-callback {
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
}
