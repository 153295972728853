.model-range {
  background-color: var(--color-4);
  padding-bottom: 0;
}

.model-range__title {
  text-transform: uppercase;
  margin-bottom: 1em;
}

.model-range__nav {
  padding-bottom: 2rem;
}

.model-range__nav .container {
  padding-left: .5rem;
  padding-right: .5rem;
}

.model-range__nav .row {
  margin-bottom: -1.5rem;
  margin-left: -.5rem;
  margin-right: -.5rem;
}

.model-range__nav .col {
  width: 50%;
  margin-bottom: 1.5rem;
  padding-left: .5rem;
  padding-right: .5rem;

  @media (--sm) {
    width: 33.333%;
  }

  @media (--lg) {
    width: 16.666%;
  }
}
