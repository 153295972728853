.footer {
  background-color: hsla(240, 4%, 9%, 1);
  color: var(--color-1);
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (--md) {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.footer__link {
  display: inline-block;
  opacity: .7;
  margin-bottom: 1.5rem;
  font-size: .8em;

  @media (--md) {
    margin-bottom: 3rem;
  }
}

.footer__copyright {
  font-size: .8em;
  margin-bottom: 1rem;

  @media (--md) {
    margin-bottom: 2rem;
  }
}

.footer__logo {
  width: 200px;
  margin: 0 auto;
}
